import { ReactNode } from "react";
import Navbar from "../components/navbar";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

type Props = {
  children?: ReactNode;
};

export default function MainLayout({ children }: Props) {
  return (
    <>
      <Helmet>
        <title>Trust Zone</title>
      </Helmet>
      <main className="flex min-h-screen flex-col">
        <Navbar />
        <div className="flex-1" style={{ fontFamily: "Battambang" }}>
          {children}
          <Outlet />
        </div>
        <Footer />
      </main>
    </>
  );
}
