import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { faEdit, faRemove, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConfirmDelete, Loading, Modal } from "../../../components";
import { public_src, request } from "../../../lib";

export default function BackendEvent() {
  const [events, setEvents] = useState<Events[]>([]);
  const [ready, setReady] = useState(false);

  const [mainImageSource, setMainImageSource] = useState("");
  const [eventImageSource, setEventImageSource] = useState<EventImage[]>([]);

  const [newEventImages, setNewEventImages] = useState<File[]>([]);
  const [newMainImage, setNewMainImage] = useState<File | null>(null);

  const [showMainImageModal, setShowMainImageModal] = useState(false);
  const [showEventImageModal, setShowEventImageModal] = useState(false);
  const [showAddEventImageModal, setShowAddEventImageModal] = useState(false);
  const [showChangeMainImageModal, setShowChangeMainImageModal] = useState(false);

  const seletedEventId = useRef<number | null>(null);

  useEffect(() => {
    request({ method: "GET", url: "events" })
      .then((res) => setEvents(res.data))
      .finally(() => setReady(true));
  }, []);

  function openMainImage(src: string) {
    setMainImageSource(src);
    setShowMainImageModal(true);
  }

  function openEventImage() {
    request({ url: `events/${seletedEventId.current}/images` })
      .then((res) => setEventImageSource(res.data))
      .finally(() => setShowEventImageModal(true));
  }

  if (!ready) return <Loading />;
  return (
    <>
      <h4 className="text-lg md:text-2xl text-center capitalize py-4 md:py-8 font-bold">List events</h4>
      <div className="text-end">
        <Link
          to="/auth/events/new"
          className="btn btn-primary mx-2 mb-5 btn-sm md:btn-md text-xs md:text-sm text-white font-semibold uppercase"
        >
          Addnew
        </Link>
      </div>
      <div className="overflow-x-auto">
        <table className="table">
          <thead>
            <tr className="font-extrabold text-sm md:text-lg bg-cyan-800 text-white capitalize">
              <th>No</th>
              <th>Title</th>
              <th>Description</th>
              <th>Main Image</th>
              <th>Event Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {events.length === 0 && (
              <tr>
                <td colSpan={6} align="center">
                  No event
                </td>
              </tr>
            )}
            {events.map((event, index) => {
              return (
                <tr key={index}>
                  <td align="center">{index + 1}</td>
                  <td className="min-w-[120px]">{event.title}</td>
                  <td className="min-w-[250px]">{event.description}</td>
                  <td align="center">
                    <button
                      onClick={() => {
                        seletedEventId.current = event.id;
                        openMainImage(event.filename);
                      }}
                    >
                      view
                    </button>
                  </td>
                  <td align="center">
                    <button
                      onClick={() => {
                        seletedEventId.current = event.id;
                        openEventImage();
                      }}
                    >
                      view
                    </button>
                  </td>
                  <td align="center">
                    <div className="flex gap-2 md:gap-3 justify-center items-center">
                      <Link to={`/auth/events/edit/${event.id}`} className="text-blue-600 ">
                        <FontAwesomeIcon icon={faEdit} size="xl" />
                      </Link>
                      <ConfirmDelete
                        onDelete={() => {
                          request({ url: `events/${event.id}`, method: "DELETE" });
                          setEvents((current) => current.filter((e) => e.id !== event.id));
                        }}
                      >
                        <FontAwesomeIcon className="text-red-500" icon={faTrash} size="xl" />
                      </ConfirmDelete>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* main image modal */}
      <Modal open={showMainImageModal} onClose={() => setShowMainImageModal(false)} size="lg" full>
        <div className="relative w-full h-[400px] md:h-[600px]">
          {mainImageSource === "" ? (
            <div className="w-full h-[400px] md:h-[600px] flex justify-center items-center">No image to display</div>
          ) : (
            <div className="relative h-[100%] w-[100%]">
              <img src={public_src(mainImageSource)} className="h-[100%] w-[100%]" alt="Main" />
              <button onClick={() => setShowChangeMainImageModal(true)} className="absolute top-0 right-0 btn btn-sm btn-primary">
                Change
              </button>
            </div>
          )}
        </div>
      </Modal>

      {/* event image modal */}
      <Modal open={showEventImageModal} onClose={() => setShowEventImageModal(false)} size="lg">
        <div className="text-end mb-3 ">
          <button className="btn-success btn btn-xs " onClick={() => setShowAddEventImageModal(true)}>
            Add
          </button>
        </div>
        {eventImageSource.length === 0 && <div className="w-full h-[200px] flex justify-center items-center">No Image display</div>}
        {eventImageSource.length > 0 && (
          <div className="grid gap-2 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {eventImageSource.map((image, index) => (
              <div key={index} className="relative w-full h-[140px] md:h-[180px]">
                <img src={public_src(image.filename)} className="w-[100%] h-[100%]" alt="event" />
                <ConfirmDelete
                  className="bg-red-500 absolute top-0 right-0"
                  onDelete={() => {
                    request({ method: "DELETE", url: "events/event-images/" + image.id });
                    setEventImageSource((current) => current.filter((d) => d.id !== image.id));
                  }}
                >
                  <FontAwesomeIcon title="Remove Image" className="text-white px-2" icon={faRemove} />
                </ConfirmDelete>
              </div>
            ))}
          </div>
        )}
      </Modal>

      {/* add new event image */}
      <Modal open={showAddEventImageModal} onClose={() => setShowAddEventImageModal(false)} size="md">
        <h4 className="font-semibold text-lg md:text-2xl text-center mb-3 md:md-5">Add Event Image</h4>
        <div className="flex w-full gap-3">
          <label className="font-semibold self-center grow text-end">Event Image</label>
          <input
            onChange={(e) => {
              const files = Array.prototype.slice.call(e.target.files);
              setNewEventImages(files);
            }}
            className="border-2 border-black rounded-md p-1 w-[70%]"
            type="file"
            accept="image/*"
            multiple
          />
        </div>
        <div className="flex gap-5 justify-center mt-3 md:mt-5">
          <button
            onClick={async () => {
              if (newEventImages) {
                await request({
                  method: "POST",
                  url: "events/event-images",
                  mulitimedia: true,
                  data: { id: seletedEventId.current, eventImages: newEventImages },
                });
                setNewEventImages([]);
                setShowAddEventImageModal(false);
                setShowEventImageModal(false);
                openEventImage();
              }
            }}
            className="btn btn-sm btn-primary text-white"
          >
            Save
          </button>
          <button onClick={() => setShowAddEventImageModal(false)} className="btn btn-sm btn-outline">
            Cancel
          </button>
        </div>
      </Modal>

      {/* update main image modal */}
      <Modal open={showChangeMainImageModal} onClose={() => setShowChangeMainImageModal(false)} size="md">
        <h4 className="font-semibold text-lg md:text-2xl text-center mb-3 md:md-5">Change Event Image</h4>
        <div className="flex w-full gap-3">
          <label className="font-semibold self-center grow text-end">Image</label>
          <input
            onChange={(e) => {
              const files = e.target.files;
              if (files) setNewMainImage(files[0]);
            }}
            className="border-2 border-black rounded-md p-1 w-[70%]"
            type="file"
            accept="image/*"
          />
        </div>
        <div className="flex gap-5 justify-center mt-3 md:mt-5">
          <button
            onClick={() => {
              if (newMainImage) {
                request({
                  method: "PUT",
                  url: "events/main-image/" + seletedEventId.current,
                  mulitimedia: true,
                  data: { mainImage: newMainImage },
                })
                  .then((res) => {
                    const data = res.data as { status: boolean; message: string; filename: string };
                    openMainImage(data.filename);
                  })
                  .finally(() => {
                    setNewMainImage(null);
                    setShowChangeMainImageModal(false);
                  });
              }
            }}
            className="btn btn-sm btn-primary text-white"
          >
            Save
          </button>
          <button onClick={() => setShowChangeMainImageModal(false)} className="btn btn-sm btn-outline">
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
}
