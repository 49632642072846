import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Banner, Container, Loading } from "../../components";
import { public_src, request, static_asset } from "../../lib";

export default function Events() {
  const itemPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [ready, setReady] = useState(false);
  const [events, setEvents] = useState<Events[]>([]);

  useEffect(() => {
    request({ url: "events" })
      .then((respone) => setEvents(respone.data))
      .finally(() => setReady(true));
  }, []);
  const totalPage = Math.ceil(events.length / itemPerPage);

  let buttons: Array<string | number> = [];

  if (totalPage > 1) {
    if (currentPage === 1) buttons = [1, "Next"];
    if (currentPage > 1 && currentPage < totalPage) buttons = ["Prev", currentPage, "Next"];
    if (currentPage === totalPage) buttons = ["Prev", currentPage];
    if (currentPage > 2 && totalPage > 2) buttons.unshift("First");
    if (totalPage > 2 && currentPage < totalPage - 1) buttons.push("Last");
  }

  function pageClick(index: number) {
    if (buttons[index] === "First") setCurrentPage(1);
    if (buttons[index] === "Prev") setCurrentPage((current) => current - 1);
    if (buttons[index] === "Next") setCurrentPage((current) => current + 1);
    if (buttons[index] === "Last") setCurrentPage(totalPage);
  }

  if (!ready) return <Loading />;
  return (
    <>
      <Banner imageUrl={static_asset("/assets/images/banner/media_banner.webp")} />
      <Container>
        {events.length === 0 && <h3 className="text-center uppercase font-bold text-xl py-5">No Content yet</h3>}

        {events.length > 0 && (
          <>
            {events.map((event, index) => {
              const upBound = currentPage * itemPerPage;
              const lowBound = (currentPage - 1) * itemPerPage;
              if (index >= lowBound && index < upBound) {
                return (
                  <div key={index} className="block md:flex gap-5 py-5 md:py-10 border-b-2">
                    <div className="relative min-w-[250px] w-[250px] md:min-w-[320px] h-[200px] md:h-[230px] mx-auto border">
                      <img alt={event.title} src={public_src(event.filename)} className="w-[100%] h-[100%]" />
                    </div>
                    <div className="grow px-5">
                      <h2 className="uppercase font-bold text-lg md:text-xl text-amber-600 pb-3 pt-3 md:pt-0 text-center md:text-left">
                        {event.title}
                      </h2>
                      <p className="text-ellipsis max-h-[100px] overflow-hidden">{event.description}</p>
                      <Link to={`/events/${event.id}`}>
                        <span className="text-blue-600 font-bold">see more ...</span>
                      </Link>
                    </div>
                  </div>
                );
              } else {
                return <></>;
              }
            })}
            <div className="pagination text-center py-3">
              <div className="join gap-1">
                {buttons.map((btn, index) => {
                  const btnActive = typeof btn === "number" ? "btn-warning" : "";
                  return (
                    <button onClick={() => pageClick(index)} key={index} className={`join-item btn-sm btn ${btnActive} `}>
                      {btn}
                    </button>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </Container>
    </>
  );
}
