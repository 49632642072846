import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { Container, Loading } from "../../components";
import { request } from "../../lib";
import NotFoundPage from "../404";

export default function JobDetail() {
  let { id: jobID } = useParams();
  const [ready, setReady] = useState(false);
  const [job, setJob] = useState<Job | null>(null);
  const [responsibilities, setResponsibilities] = useState<JobResponsibily[] | null>(null);
  const [requirements, setRequirements] = useState<JobRequirement[] | null>(null);

  useEffect(() => {
    (async () => {
      if (jobID) {
        await request({ url: `jobs/${jobID}` }).then((respone) => setJob(respone.data[0]));
        await request({ url: `jobs/${jobID}/responsibilities` }).then((respone) => setResponsibilities(respone.data));
        await request({ url: `jobs/${jobID}/requirements` }).then((respone) => setRequirements(respone.data));
        setReady(true);
      }
    })();
  }, [jobID]);

  if (!ready) return <Loading />;
  if (!job) return <NotFoundPage />;

  return (
    <Container>
      <div className="mx-3 md:mx-0">
        <h3 className="text-xl md:text-3xl font-bold uppercase my-5 md:my-10">{job.title}</h3>
        <div>
          <span className="font-bold">Post Date :</span> {dayjs(job.post_date).format("DD-MM-YYYY")}
        </div>
        <div>
          <span className="font-bold">Close Date :</span> {dayjs(job.close_date).format("DD-MM-YYYY")}
        </div>
        <div>
          <span className="font-bold">Job Type :</span> {job.type}
        </div>
        <div>
          <span className="font-bold">Location :</span> {job.location}
        </div>
      </div>
      <div className="mx-3 md:mx-0">
        <h5 className="text-md md:text-lg font-semibold my-3 md:my-6">Job Responsibilities</h5>
        <ul className="list-disc">
          {responsibilities?.map((res, index) => (
            <li className="ml-4 md:ml-6" key={index}>
              {res.responsibility}
            </li>
          ))}
        </ul>
      </div>
      <div className="mx-3 md:mx-0">
        <h5 className="text-md md:text-lg font-semibold my-3 md:my-6">Key Experiences</h5>
        <ul className="list-disc">
          {requirements?.map((req, index) => (
            <li className="ml-4 md:ml-6" key={index}>
              {req.requirement}
            </li>
          ))}
        </ul>
      </div>
      <div className="mx-3 md:mx-0 mb-3 md:mb-6">
        <h5 className="text-md md:text-lg font-semibold my-3 md:my-6">How to Apply</h5>
        <p className="mb-3">Interested candidates are invited to send their CV and cover letter to HR department via:</p>
        <div>
          <span className="font-bold">Email : </span>
          <a href=" hra@worldbridge.com.kh" target="_blank" className="underline">
            hra@worldbridge.com.kh
          </a>
        </div>
        <div>
          <span className="font-bold">Phone : </span>
          (+855)93 333 826 / (+855)85 777 889
        </div>
        <div>
          <span className="font-bold">Address : </span>
          P.G.C.T Building, Street 274, Sangkat Tonle Bassac, Khan Chamkarmom, Phnom Penh
        </div>
      </div>
    </Container>
  );
}
