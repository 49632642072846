import { ReactNode } from "react";
import BackendNavbar from "../components/backend-navbar";
import { Navigate, Outlet } from "react-router-dom";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

type Props = {
  children?: ReactNode;
};

export default function BackendLayout({ children }: Props) {
  const login = sessionStorage.getItem("login");
  const user = sessionStorage.getItem("user");

  if (!login || !user) {
    return <Navigate to="/backend-login" />;
  }

  return (
    <>
      <Helmet>
        <title>Trust Zone</title>
      </Helmet>
      <main className="flex min-h-screen flex-col">
        <BackendNavbar />
        <div className="flex-1" style={{ fontFamily: "Battambang" }}>
          {children}
          <Outlet />
        </div>
        <Footer />
      </main>
    </>
  );
}
