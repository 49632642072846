import { static_asset } from "../lib";

export const services = [
  {
    title: "automotives",
    path: "/services/automotives",
    imageUrl: static_asset("/assets/images/services/automotives.webp"),
  },
  {
    title: "jewelries",
    path: "/services/jewelries",
    imageUrl: static_asset("/assets/images/services/jewelries.webp"),
  },
  {
    title: "electronics",
    path: "/services/electronics",
    imageUrl: static_asset("/assets/images/services/electronics.webp"),
  },
  {
    title: "real estate",
    path: "/services/real-estate",
    imageUrl: static_asset("/assets/images/services/real_estate.webp"),
  },
];

export const typeOption = [
  {
    value: "Full Time",
    label: "Full Time",
  },
  {
    value: "Part Time",
    label: "Part Time",
  },
];

export const nav_item = [];
