import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { public_src, request, static_asset } from "../../lib";
import { Loading, Banner, Container } from "../../components";
import NotFoundPage from "../404";

export default function EventDetail() {
  const params = useParams();
  const [event, setEvent] = useState<Events | null>(null);
  const [eventImages, setEventImages] = useState<EventImage[]>([]);
  const [ready, setReady] = useState(false);
  const eventID = Number(params?.id);

  useEffect(() => {
    (async () => {
      await request({ url: `events/${eventID}` }).then((res) => setEvent(res.data[0]));
      await request({ url: `events/${eventID}/images` }).then((res) => setEventImages(res.data));
      setReady(true);
    })();
  }, [eventID]);

  if (!ready) return <Loading />;
  if (!event) return <NotFoundPage />;

  return (
    <>
      <Banner imageUrl={static_asset("/assets/images/banner/media_banner.webp")} />
      <Container>
        <h3 className="text-lg md:text-2xl text-center font-bold py-5 md:py-10">{event.title}</h3>
        <div className="w-full h-[400px] md:h-[700px] relative ">
          <img loading="lazy" alt={event.filename} className="h-[100%] w-[100%]" src={public_src(event.filename)} />
        </div>
        <p className="py-5 md:py-10 px-5 md:px-0">{event.description}</p>
        <div className="py-5 md:py-10 px-5 md:px-0">
          សម្រាប់ពត៌មាន លក់សិទ្ធិអាជីវកម្ម សូមទាក់ទងមកលេខ 017 236 777
          <br /> Please highly recommended Cash-U-Up on Working Hour
          <br />
          <br />
          <ul className="list-disc px-5 md:px-10">
            <li>Monday - Friday (8:30 AM - 6:00 PM)</li>
            <li>Saturday (8:30 AM - 4:00 PM)</li>
          </ul>
          <br />
          <br /> For more info, please contact:
          <br /> <b>Head Office</b>: 085 777 889 / 093 333 826
          <br /> <b>Facebook Page</b> :{" "}
          <a rel="noreferrer" className="text-yellow-600 underline" target="_blank" href="https://www.facebook.com/CashUUpKH">
            https://www.facebook.com/CashUUpKH
          </a>
          <br /> <b>Website</b> :{" "}
          <a rel="noreferrer" className="text-yellow-600 underline" target="_blank" href="https://cashuup.com.kh">
            https://cashuup.com.kh
          </a>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-0 md:gap-3 lg:gap-5 pb-5">
          {eventImages.map((image, index) => {
            return (
              <div key={index} className="relative mx-auto md:mx-0 w-[350px] md:w-full h-[300px]">
                <img loading="lazy" alt={image.filename} src={public_src(image.filename)} className="w-[100%] h-[100%]" />
              </div>
            );
          })}
        </div>
      </Container>
    </>
  );
}
