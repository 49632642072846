import { useRef, ReactNode } from "react";

type Props = {
  onDelete: () => void;
  title?: string;
  content?: string;
  children: ReactNode;
  className?: string;
};
export default function ConfirmDelete(props: Props) {
  const { title = "Delete", content = "Are you sure want to delete this ?", onDelete, children, className } = props;

  const modalRef = useRef<HTMLDialogElement>(null);

  return (
    <>
      <button className={className} onClick={() => modalRef.current?.showModal()}>
        {children}
      </button>
      <dialog className="modal" ref={modalRef}>
        <div className="modal-box w-11/12 max-w-xl py-8 px-4 backdrop:rounded-none">
          <h3 className="font-bold text-xl md:text-3xl mb-3 text-start">{title}</h3>
          <p className="my-3 md:my-6 text-lg md:text-2xl font-semibold text-center">{content}</p>
          <div className="justify-end mt-3 flex gap-3">
            <button
              onClick={() => {
                onDelete();
                modalRef.current?.close();
              }}
              className="btn btn-error text-white btn-sm"
            >
              Delete
            </button>
            <button onClick={() => modalRef.current?.close()} className="btn btn-outline btn-sm ">
              Cancel
            </button>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
}
