import { Container, Banner } from "../components";
import { static_asset } from "../lib";

export default function AboutUs() {
  const aboutUsContents = [
    {
      title: "mission",
      imageUrl: static_asset("/assets/images/about-us/mission.webp"),
      description: "Make access to fund available, fast and easy for everyone in need, contributing to socio-economic development.",
    },
    {
      title: "vision",
      imageUrl: static_asset("/assets/images/about-us/vision.webp"),
      description: "Be a pioneering cash converter for all assets available, partnering with all stakeholders in the region.",
    },
    {
      title: "value proposition",
      imageUrl: static_asset("/assets/images/about-us/value_proposition.webp"),
      description: "We enable everyone to get quick money by offering a simple secured access to finance in minutes.",
    },
  ];

  function Content({
    title,
    imageUrl,
    description,
    imageRight,
  }: {
    title: string;
    imageUrl: string;
    description: string;
    imageRight: boolean;
  }) {
    const order = imageRight ? "order-last" : "order-first";
    return (
      <div className="block md:flex flex-1 py-8 border-t-2">
        <div className={"w-full md:w-1/2 grow md:grow-0 relative h-[200px] md:h-[250px] " + order}>
          <img className="m-auto w-[100%] h-[100%]" alt={title} src={imageUrl} />
        </div>
        <div className={"w-full md:w-1/2 grow px-3"}>
          <div className="uppercase font-bold text-center md:text-left text-2xl pb-2 mt-3 md:mt-0">{title}</div>
          <p>{description}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <Banner imageUrl={static_asset("/assets/images/banner/about_us_banner.webp")} />
      <Container>
        <div className="block md:flex flex-1 py-8 ">
          <div className={"w-full md:w-1/2"}>
            <div className="relative h-[150px] w-[150px] md:h-[200px] md:w-[200px] m-auto ">
              <img alt="123" src={static_asset("/assets/logo/logo.webp")} className="w-[100%] h-[100%]" />
            </div>
          </div>
          <p className={"w-full md:w-2/3 px-3 mt-5 md:mt-0"}>
            With business experience of pawn shop more than 40 years in Singapore Cash-U-Up expand its business in the Cambodia, with
            participation from Cambodian investors and Singapore. Cash-U-Up is the 4th pawn shop who registered with the Ministry of
            commerce under the trademark (Cash-U-Up Co., Ltd), and the pawning licensed No.010សហវ.ឧហ on 31 March 2010 from the Ministry of
            Economy and Finance. Cash-U-Up was open its central office in Phnom Penh located on Korean Commercial Building, Street 274,
            SangkatBasak, KhanChamkarmon, on July 01, 2010, with the pawning services such as Jewelries, cars, motorcycles, electronic
            materials with the acceptable interest rate in accordance with the law, especially we are sever and keep the security of pawning
            collateral for customers is our main commitment.
          </p>
        </div>
        {aboutUsContents.map((item, index) => (
          <Content
            key={index}
            title={item.title}
            imageUrl={item.imageUrl}
            description={item.description}
            imageRight={index % 2 !== 0 ? false : true}
          />
        ))}
      </Container>
    </>
  );
}
