import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { request } from "../../../lib";
import { Input, Container, Loading, ConfirmDelete } from "../../../components";

export default function BackendPublicHoliday() {
  const thisYear = new Date().getFullYear();
  const [year, setYear] = useState(thisYear);
  const [ready, setReady] = useState(false);
  const [data, setData] = useState<PublicHoliday[]>([]);

  // modal ref
  const newModalRef = useRef<HTMLDialogElement>(null);
  const editModalRef = useRef<HTMLDialogElement>(null);

  const selectedRef = useRef<number | null>(null);

  // form state
  const [newDate, setNewDate] = useState<Date | null>(null);
  const [newDescription, setNewDescription] = useState<string>("");

  const [editDate, setEditDate] = useState<Date | null>(null);
  const [editDescription, setEditDescription] = useState<string>("");
  const [newTitle, setNewTitle] = useState("");
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    (async function () {
      setNewTitle("");
      await request({ url: `public-holiday`, params: { year } }).then((res) => setData(res.data));
      await request({ url: `public-holiday/title/${year}` }).then((res) => {
        const data: PublicHolidayTitle[] = res.data;
        if (data) {
          const title = data[0];
          if (title) setNewTitle(title.title);
        }
      });
      setReady(true);
    })();
  }, [year]);

  function openEdit(holiday: PublicHoliday) {
    setEditDate(holiday.date);
    setEditDescription(holiday.description);
    selectedRef.current = holiday.id;
    editModalRef.current?.showModal();
  }

  const listYear = [];
  for (let i = thisYear - 10; i <= thisYear + 5; i++) {
    listYear.push({ value: i, label: i });
  }

  async function saveEdit() {
    if (!newDate || !newDescription) return;
    const res = await request({
      url: `public-holiday/${selectedRef.current}`,
      method: "PUT",
      data: { date: editDate, description: editDescription },
    });
    const updateHoliday: PublicHoliday = res.data;
    if (updateHoliday) {
      const index = data.findIndex((d) => d.id === selectedRef.current);
      const updateObj: PublicHoliday = { ...data[index], description: editDescription, date: editDate! };
      setData((current) => [...current.slice(0, index), updateObj, ...current.slice(index + 1)]);
    }
    editModalRef.current?.close();
  }

  async function saveNew() {
    if (!newDate || !newDescription) return;
    const res = await request({ url: "public-holiday", method: "POST", data: { date: newDate, description: newDescription } });
    const newHoliday: PublicHoliday = res.data;
    if (newHoliday) setData((current) => [...current, newHoliday]);
    setNewDate(new Date());
    setNewDescription("");
    newModalRef.current?.close();
  }

  if (!ready) return <Loading />;
  return (
    <Container>
      <h3 className="text-lg md:text-2xl font-semibold text-center my-3 md:my-6">Public Holiday</h3>
      <div className="flex justify-between mb-3 md:by-6">
        <select
          onChange={(e) => setYear(Number(e.target.value))}
          className="border-2 px-3 md:px-6 rounded-md border-black"
          defaultValue={thisYear}
        >
          {listYear.map((option, index) => (
            <option value={option.value} key={index}>
              {option.label}
            </option>
          ))}
        </select>
        <button onClick={() => newModalRef.current?.showModal()} className="btn btn-success btn-xs md:btn-sm">
          Addnew
        </button>
      </div>
      <div className="my-3 md:my-6">
        <div className="text-center font-semibold">Title</div>
        <div className="flex gap-3">
          <textarea
            className="grow border-2 border-black rounded-md px-3"
            rows={4}
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
          />
          <button
            title="save title"
            onClick={() => {
              request({ url: `public-holiday/title/${year}`, method: "POST", data: { title: newTitle } }).then(() => {
                setShowToast(true);
                setTimeout(() => {
                  setShowToast(false);
                }, 3000);
              });
            }}
            className="text-lime-600 font-extrabold "
          >
            <FontAwesomeIcon icon={faCheck} />
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="table">
          <thead>
            <tr className="font-extrabold text-sm md:text-lg bg-cyan-800 text-white capitalize">
              <th className="w-[60px] lg:w-[100px]">No</th>
              <th className="w-[100px] lg:w-[200px]">Date</th>
              <th>Description</th>
              <th className="w-[120px] lg:w-[200px]">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 && (
              <tr>
                <td colSpan={4} align="center">
                  Not yet set for this year
                </td>
              </tr>
            )}
            {data.map((holiday, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{dayjs(holiday.date).format("DD-MM-YYYY")}</td>
                <td>{holiday.description}</td>
                <td className="flex gap-3 ">
                  <button onClick={() => openEdit(holiday)} className="text-cyan-700">
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <ConfirmDelete
                    onDelete={() => {
                      request({ url: `public-holiday/${holiday.id}`, method: "DELETE" });
                      setData((current) => current.filter((d) => d.id !== holiday.id));
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} className="text-red-500" />
                  </ConfirmDelete>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* new holiday modal */}
      <dialog ref={newModalRef} className="modal">
        <div className="modal-box w-11/12 max-w-xl py-8 backdrop:rounded-none">
          <h4 className="md:text-lg font-semibold capitalize mb-3 md:mb-5">Add new holiday</h4>
          <Input
            type="date"
            label="Date"
            value={dayjs(newDate).format("YYYY-MM-DD")}
            onChange={(e) => setNewDate(new Date(e.target.value))}
            required
          />
          <Input
            type="textarea"
            label="Description"
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
            rows={4}
            required
          />
          <div className="justify-center mt-3 flex gap-3">
            <button onClick={saveNew} className="btn btn-primary btn-sm">
              Save
            </button>
            <button onClick={() => newModalRef.current?.close()} className="btn btn-error btn-sm">
              Close
            </button>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
      {/* edit holiday modal */}
      <dialog ref={editModalRef} className="modal">
        <div className="modal-box w-11/12 max-w-xl py-8 backdrop:rounded-none">
          <h4 className="md:text-lg font-semibold capitalize mb-3 md:mb-5">edit holiday</h4>
          <div className="flex w-full gap-3 mt-3">
            <label className="font-semibold self-center grow text-end">Date</label>
            <input
              value={dayjs(editDate).format("YYYY-MM-DD")}
              onChange={(e) => setEditDate(new Date(e.target.value))}
              type="date"
              className="border-2 border-black rounded-md p-1 w-[70%]"
            />
          </div>
          <div className="flex w-full gap-3 mt-3">
            <label className="font-semibold self-center grow text-end">Description</label>
            <textarea
              value={editDescription}
              onChange={(e) => setEditDescription(e.target.value)}
              rows={4}
              className="border-2 border-black rounded-md p-1 w-[70%]"
            />
          </div>
          <div className="justify-center mt-3 flex gap-3">
            <button onClick={saveEdit} className="btn btn-primary btn-sm">
              Save
            </button>
            <button onClick={() => editModalRef.current?.close()} className="btn btn-error btn-sm">
              Close
            </button>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
      {showToast && (
        <div className="toast toast-top toast-center">
          <div className="alert alert-info">
            <span>Title have been updated.</span>
          </div>
        </div>
      )}
    </Container>
  );
}
