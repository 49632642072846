type Props = {
  label?: string;
  required?: boolean;
  type?: "text" | "password" | "date" | "number" | "select" | "textarea";
  rows?: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value: string | number;
  options?: { label: string; value: any }[];
  name?: string;
  placeholder?: string;
  disabled?: boolean;
};
export default function Input(props: Props) {
  const {
    type = "text",
    label,
    required = false,
    onChange,
    rows = 3,
    value,
    options,
    name = "",
    placeholder = "",
    disabled = false,
  } = props;

  const isRequired = required ? <span className="text-red-500 font-semibold "> * </span> : "";
  const isDisabled = disabled ? "bg-zinc-300" : "";
  return (
    <div className="block md:flex w-full gap-3 mt-3 md:mt-5">
      <label className="font-semibold self-center grow text-start md:text-end block mb-1 md:mb-0">
        {label}
        {isRequired}
      </label>
      {type === "textarea" && (
        <textarea
          className={`border-2 border-black rounded-md p-1 w-[100%] md:w-[70%] ${isDisabled}`}
          value={value}
          onChange={(e) => onChange(e)}
          rows={rows}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
        ></textarea>
      )}

      {type === "select" && (
        <select
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          defaultValue={value}
          className={`border-2 border-black rounded-md p-1 w-[100%] md:w-[70%] ${isDisabled}`}
        >
          {options?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )}

      {type !== "textarea" && type !== "select" && (
        <input
          name={name}
          type={type}
          value={value}
          onChange={(e) => onChange(e)}
          placeholder={placeholder}
          disabled={disabled}
          className={`border-2 border-black rounded-md p-1 w-[100%] md:w-[70%] ${isDisabled}`}
        />
      )}
    </div>
  );
}
