import Carousel, { CarouselItems } from "./carousel";
type BannerProps = {
  imageUrl: string | CarouselItems[];
  height?: string;
};

export default function Banner(props: BannerProps) {
  const { imageUrl, height = "100%" } = props;

  function ImageBanner() {
    let src = "";
    if (typeof imageUrl === "string") {
      src = imageUrl;
      return (
        <div className={`h-[${height}]`}>
          <img src={src} className="w-[100%] h-[100%]" alt="banner" />
        </div>
      );
    } else {
      return (
        <div className={`h-[${height}]`}>
          <Carousel items={imageUrl} />
        </div>
      );
    }
  }

  return <div className="w-full h-[150px] md:h-[250px] relative">{<ImageBanner />}</div>;
}
