import { Banner, Container } from "../../components";
import { static_asset } from "../../lib";

export default function CareerCenter() {
  const career_images = [
    static_asset("/assets/images/career/career_1.webp"),
    static_asset("/assets/images/career/career_2.webp"),
    static_asset("/assets/images/career/career_3.webp"),
    static_asset("/assets/images/career/career_4.webp"),
    static_asset("/assets/images/career/career_5.webp"),
  ];
  return (
    <>
      <Banner imageUrl={static_asset("/assets/images/banner/careers_banner.webp")} />
      <Container>
        <h2 className="text-center md:text-left text-lg md:text-2xl my-3 md:my-6 uppercase font-semibold">
          WELCOME TO THE TRUST ZONE CAREER CENTER!
        </h2>
        <p className="mx-3 md:mx-0 my-3 md:my-5">
          Working at TRUST ZONE pawnshop is more than a job, it is an opportunity to work in a fun and challenging environment of
          motivation, dynamism and creativity will make you grow professionally.
        </p>
        <p className="mx-3 md:mx-0 my-3 md:my-5">
          Your success matters to us! That is why we always welcome new people by providing training and development opportunities as you
          need and desire.
        </p>
        <p className="mx-3 md:mx-0 my-3 md:my-5">
          If you are passionate about working in a leading pawnshop business, please consider TRUST ZONE as a positive environment with team
          support, competitive pay, and personalized development.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mb-3 md:mb-6">
          {career_images.map((image, index) => (
            <div key={index} className="relative mx-auto w-[350px] md:w-full h-[250px] md:h-[300px]">
              <img src={image} alt={`image_${index}`} className="w-[100%] h-[100%]" />
            </div>
          ))}
        </div>
      </Container>
    </>
  );
}
