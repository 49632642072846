import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { request } from "../../../lib";
import { typeOption } from "../../../data";
import { Container, Input } from "../../../components";

export default function NewJob() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [department, setDepartment] = useState("");
  const [location, setLocation] = useState("");
  const [type, setType] = useState("Full time");
  const [postDate, setPostDate] = useState<Date | null>(null);
  const [closeDate, setCloseDate] = useState<Date | null>(null);

  const [error, setError] = useState(false);

  function save() {
    if (!title || !department || !location || !type || !postDate || !closeDate) {
      setError(true);
      return;
    }
    request({
      method: "POST",
      url: "jobs",
      data: {
        title,
        department,
        location,
        type,
        post_date: postDate,
        close_date: closeDate,
      },
    }).then((res) => {
      navigate("/auth/jobs");
    });
  }

  return (
    <Container>
      <h3 className="font-semibold text-center text-lg md:text-2xl my-3 md:my-6">Add New Job</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 px-3 md:px-0 ">
        <Input required label="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
        <Input required label="Department" value={department} onChange={(e) => setDepartment(e.target.value)} />
        <Input required label="Location" value={location} onChange={(e) => setLocation(e.target.value)} />
        <Input
          required
          type="date"
          label="Post Date"
          value={dayjs(postDate).format("YYYY-MM-DD")}
          onChange={(e) => setPostDate(new Date(e.target.value))}
        />
        <Input
          required
          type="date"
          label="Close Date"
          value={dayjs(closeDate).format("YYYY-MM-DD")}
          onChange={(e) => setCloseDate(new Date(e.target.value))}
        />
        <Input type="select" label="Type" value={type} onChange={(e) => setType(e.target.value)} options={typeOption} required />
      </div>
      {error && <div className="text-end font-semibold text-red-500 my-3">All field cannot empty</div>}
      <div className="justify-center my-5 flex gap-3">
        <button className="btn btn-primary text-white" onClick={save}>
          Save
        </button>
        <Link to="/auth/jobs" className="btn btn-error text-white">
          Back
        </Link>
      </div>
    </Container>
  );
}
