import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldHalved, faBoltLightning, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { services } from "../data";
import { CarouselItems } from "../components/carousel";
import { Container, Banner, Carousel } from "../components";
import { static_asset } from "../lib";

export default function Home() {
  const features = [
    {
      title: "secure",
      description: "Your property is under our secured watch!",
      icon: faShieldHalved,
    },
    {
      title: "quick",
      description: "Get quick cash in minutes!",
      icon: faBoltLightning,
    },
    {
      title: "choices",
      description: "Convert any properties into cash based on your preference!",
      icon: faCircleCheck,
    },
  ];
  const banner_url: CarouselItems[] = [
    {
      imageUrl: static_asset("/assets/images/banner/home_banner_1.webp"),
    },
    {
      imageUrl: static_asset("/assets/images/banner/about_us_banner.webp"),
    },
    {
      imageUrl: static_asset("/assets/images/banner/services_banner.webp"),
    },
  ];

  return (
    <>
      <Banner imageUrl={banner_url} />
      <Container>
        <div className="block md:flex gap-4 py-4 md:py-8">
          {features.map((feature, index) => (
            <div key={index} className="text-center px-6 md:px-0 w-full md:w-1/3 mt-3 md:mt-0">
              <FontAwesomeIcon icon={feature.icon} className="text-5xl md:text-7xl" color="#FF8A00" />
              <h3 className="uppercase font-bold text-md md:text-lg py-2 md:py-4">{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
        <div className="text-center p-3 md:p-6 w-full md:w-[80%] lg:w-[60%] mx-auto">
          <h1 className="text-xl md:text-3xl  py-3 md:py-6 font-bold uppercase">welcome</h1>
          <p>
            With business experience of pawn shop more than 40 years in Singapore Cash-U-Up expand its business in the Cambodia, with
            participation from Cambodian investors and Singapore.
          </p>
        </div>
        <h2 className="text-lg md:text-2xl py-3 md:py-6 font-bold text-center uppercase">our services</h2>
        <div className="w-full md:w-[80%] lg:w-[60%] h-[300px] md:h-[400px] mx-auto mb-8 md:mb-16">
          <Carousel items={services} />
        </div>
      </Container>
    </>
  );
}
