import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { request } from "../../../lib";
import { Input, Container, Loading } from "../../../components";

export default function EditEvent() {
  const [ready, setReady] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const navigate = useNavigate();
  const params = useParams();
  const event_id = Number(params.id);
  if (!event_id) navigate("/auth/events", { replace: true });
  useEffect(() => {
    (async () => {
      await request({ url: `events/${event_id}` }).then((res) => {
        const event: Events = res.data[0];
        if (event) {
          setTitle(event.title);
          setDescription(event.description);
        }
      });
      setReady(true);
    })();
  }, [event_id]);

  async function save() {
    await request({ url: `events/${event_id}`, method: "PUT", data: { title, description } });
    navigate("/auth/events");
  }

  if (!ready) return <Loading />;
  return (
    <Container>
      <h3 className="text-center font-semibold text-lg md:text-3xl capitalize my-3 md:my-6">Edit event</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 px-3 md:px-0">
        <Input required type="textarea" value={title} onChange={(e) => setTitle(e.target.value)} label="Title" rows={4} />
        <Input required type="textarea" value={description} onChange={(e) => setDescription(e.target.value)} label="Description" rows={4} />
      </div>
      <div className="justify-center my-5 flex gap-3">
        <button onClick={save} className="btn btn-primary text-white">
          Save
        </button>
        <Link to="/auth/events" className="btn btn-error text-white">
          Back
        </Link>
      </div>
    </Container>
  );
}
