import { useEffect, useState } from "react";
import { Container, Loading } from "../../components";
import { khmer_date, khmer_number, request } from "../../lib";
export default function PublicHoliday() {
  const [table, setTable] = useState<PublicHoliday[]>([]);
  const [ready, setReady] = useState(false);
  const [title, setTitle] = useState<PublicHolidayTitle | null>(null);
  const year = new Date().getFullYear();

  useEffect(() => {
    (async function () {
      await request({ url: "public-holiday" }).then((res) => setTable(res.data));
      await request({ url: `public-holiday/title/${year}` }).then((res) => setTitle(res.data[0]));
      setReady(true);
    })();
  }, [year]);

  if (!ready) return <Loading />;

  return (
    <Container>
      <div className="text-md md:text-xl text-center font-semibold my-3 md:my-6">
        <h2>សេចក្តីជូនដំណឹង</h2>
        <h2>ស្តីពី</h2>
        <h2>ការឈប់សម្រាក ប្រចាំឆ្នាំ{khmer_number(year.toString())}</h2>
      </div>
      {title && <p className="px-3 md:px-0">{title.title}</p>}
      {table.length === 0 && <h3 className="my-5 font-semibold text-center text-lg">No public holiday set yet.</h3>}

      {table.length > 0 && (
        <table className="table-fixed border border-slate-400 w-full rounded-lg my-3 md:my-6">
          <thead>
            <tr className="bg-amber-500">
              <th className="w-[30%] border px-3 md:px-6 py-2 md:py-5">កាលបរិច្ឆេទ</th>
              <th className="w-[70%] border px-3 md:px-6 py-2 md:py-5">ទិវាបុណ្យជាតិ និង អន្តរជាតិ</th>
            </tr>
          </thead>
          <tbody>
            {table.map((tr, index) => (
              <tr key={index}>
                <td className="border text-center px-2 md:px-4 py-1 md:py-2 text-sm md:text-md">{khmer_number(khmer_date(tr.date))}</td>
                <td className="border text-center px-2 md:px-4 py-1 md:py-2 text-sm md:text-md">{tr.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Container>
  );
}
