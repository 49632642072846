import { Link } from "react-router-dom";
import { Container } from "../../components";

export default function BackendHome() {
  return (
    <Container>
      <div className="w-full lg:w-[60%] mx-auto px-5 md:px-0">
        <h2 className="text-xl md:text-2xl text-center font-extrabold uppercase my-5">Website admin page</h2>
        <h3 className="my-3 md:text-xl font-semibold">List menu you can manage</h3>
        <ul className="list-disc">
          <li className="ml-5">
            <Link to="/auth/events">Events</Link>
          </li>
          <li className="ml-5">
            <Link to="/auth/jobs">Jobs</Link>
          </li>
          <li className="ml-5">
            <Link to="/auth/public-holiday">Public Holiday</Link>
          </li>
        </ul>
      </div>
    </Container>
  );
}
