import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import MainLayout from "./layout/main";
import Home from "./pages/home";
import NotFoundPage from "./pages/404";
import AboutUs from "./pages/about-us";
import Services from "./pages/services/services";
import Automotives from "./pages/services/automotives";
import Electronics from "./pages/services/electronics";
import Jewelries from "./pages/services/jewelries";
import RealEstate from "./pages/services/real-estate";
import DiscoveryJobs from "./pages/careers/discover-jobs";
import JobDetail from "./pages/careers/jobs-detaild";
import PublicHoliday from "./pages/careers/public-holiday";
import EventDetail from "./pages/events/events-detail";
import Events from "./pages/events/events";
import CareerCenter from "./pages/careers/career";
import Login from "./pages/backend/login";
import BackendLayout from "./layout/backend";
import BackendHome from "./pages/backend";

import BackendPublicHoliday from "./pages/backend/public-holiday";
import BackendJobs from "./pages/backend/jobs";
import NewJob from "./pages/backend/jobs/new";
import EditJob from "./pages/backend/jobs/edit";
import BackendEvent from "./pages/backend/events";
import NewEvent from "./pages/backend/events/new";
import EditEvent from "./pages/backend/events/edit";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/backend-login" element={<Login />} />
        {/* backend route */}
        <Route element={<BackendLayout />}>
          <Route path="/auth" element={<BackendHome />} />
          <Route path="/auth/events" element={<BackendEvent />} />
          <Route path="/auth/events/new" element={<NewEvent />} />
          <Route path="/auth/events/edit/:id" element={<EditEvent />} />
          <Route path="/auth/jobs" element={<BackendJobs />} />
          <Route path="/auth/jobs/new" element={<NewJob />} />
          <Route path="/auth/jobs/edit/:id" element={<EditJob />} />
          <Route path="/auth/public-holiday" element={<BackendPublicHoliday />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />

          {/* services */}
          <Route path="/services" element={<Services />} />
          <Route path="/services/automotives" element={<Automotives />} />
          <Route path="/services/electronics" element={<Electronics />} />
          <Route path="/services/jewelries" element={<Jewelries />} />
          <Route path="/services/real-estate" element={<RealEstate />} />

          {/* events */}
          <Route path="/events" element={<Events />} />
          <Route path="/events/:id" element={<EventDetail />} />

          {/* carerr */}
          <Route path="/career" element={<CareerCenter />} />
          <Route path="/career/discover-jobs" element={<DiscoveryJobs />} />
          <Route path="/career/discover-jobs/:id" element={<JobDetail />} />
          <Route path="/career/public-holiday" element={<PublicHoliday />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

