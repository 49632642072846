import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading, Banner, Container } from "../../components";
import { request, static_asset } from "../../lib";
import NotFoundPage from "../404";
export default function DiscoveryJobs() {
  const [ready, setReady] = useState(false);
  const [jobs, setJobs] = useState<Job[]>([]);

  const navigate = useNavigate();

  function jobDetail(id: number) {
    navigate("/career/discover-jobs/" + id);
  }

  useEffect(() => {
    request({ url: "jobs" })
      .then((respone) => setJobs(respone.data))
      .finally(() => setReady(true));
  });
  if (!ready) return <Loading />;
  if (!jobs) return <NotFoundPage />;

  return (
    <>
      <Banner imageUrl={static_asset("/assets/images/banner/careers_banner.webp")} />
      <Container>
        {jobs.length === 0 && <h3 className="text-center font-bold text-xl py-5">No Content yet</h3>}

        {jobs.length > 0 && (
          <>
            <h4 className=" my-3 md:my-6 text-center text-lg md:text-2xl font-semibold uppercase">We are looking for</h4>
            <div className="mx-1 md:mx-0">
              <table className=" table-fixed w-full border-separate rounded-md border border-yellow-600 my-5 md:my-10  text-sm md:text-md">
                <thead>
                  <tr className="text-left">
                    <th className="px-3 md:px-6 py-2 md:py-4">Title</th>
                    <th className="px-3 md:px-6 py-2 md:py-4">Function</th>
                    <th className="px-3 md:px-6 py-2 md:py-4">Location</th>
                  </tr>
                </thead>
                <tbody>
                  {jobs.map((job, index) => {
                    const isEvent = index % 2 === 0 ? "bg-slate-100" : "";
                    return (
                      <tr className={`cursor-pointer ${isEvent} hover:bg-slate-200 `} key={index} onClick={() => jobDetail(job.id)}>
                        <td className="px-2 md:px-4 py-1 md:py-2 capitalize">{job.title}</td>
                        <td className="px-2 md:px-4 py-1 md:py-2 capitalize">{job.department}</td>
                        <td className="px-2 md:px-4 py-1 md:py-2 capitalize">{job.location}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </Container>
    </>
  );
}
