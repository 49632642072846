import { Link, useLocation } from "react-router-dom";

export default function BackendNavbar() {
  const location = useLocation();
  const url_path = location.pathname;

  const nav_item = [
    {
      name: "home",
      path: "/auth",
      is_active: url_path === "/auth",
    },
    {
      name: "events",
      path: "/auth/events",
      is_active: url_path.startsWith("/auth/events"),
    },
    {
      name: "jobs",
      path: "/auth/jobs",
      is_active: url_path.startsWith("/auth/jobs"),
    },
    {
      name: "public holiday",
      path: "/auth/public-holiday",
      is_active: url_path.startsWith("/auth/public-holiday"),
    },
  ];
  return (
    <nav className="navbar bg-base-100 shadow-md md:px-10">
      <div className="navbar-start"></div>
      <div className="navbar-center flex font-semibold">
        <ul className="menu menu-horizontal text-xs md:text-lg">
          {nav_item.map((item, index) => (
            <li key={index}>
              <Link className={item.is_active ? "btn-active" : ""} to={item.path}>
                {item.name.toUpperCase()}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="navbar-end">
        <Link target="_blank" className="text-xs md:text-sm" to="/">
          view website
        </Link>
      </div>
    </nav>
  );
}
