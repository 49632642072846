import axios from "axios";
import dayjs from "dayjs";

// const base_url = "http://localhost:9000";
const base_url = "https://cashuup.trustzonecambodia.com";

export function public_src(filename: string) {
  return base_url + "/public/" + filename;
}

export async function request({
  method = "GET",
  url,
  params,
  data,
  mulitimedia,
}: {
  method?: "GET" | "POST" | "PUT" | "DELETE";
  url: string;
  params?: {
    [index: string]: any;
  };
  data?: {
    [index: string]: any;
  };
  mulitimedia?: boolean;
}) {
  let content_type: string = "application/json";
  let formData: FormData | null = null;
  if (mulitimedia) {
    content_type = "multipart/form-data";
    formData = new FormData();
    if (data) {
      for (const [key, value] of Object.entries(data!)) {
        if (Array.isArray(value)) {
          value.forEach((item) => formData?.append(key, item));
        } else {
          formData.append(key, value);
        }
      }
    }
  }

  const result = await axios({
    method,
    params,
    url: `${base_url}/${url}`,
    data: formData ? formData : data,
    headers: {
      "Content-Type": content_type,
      Authorization: sessionStorage.getItem("user"),
    },
  });
  return result;
}

export function khmer_number(text: string) {
  let result = text.toString();
  result = result.replaceAll("0", "០");
  result = result.replaceAll("1", "១");
  result = result.replaceAll("2", "២");
  result = result.replaceAll("3", "៣");
  result = result.replaceAll("4", "៤");
  result = result.replaceAll("5", "៥");
  result = result.replaceAll("6", "៦");
  result = result.replaceAll("7", "៧");
  result = result.replaceAll("8", "៨");
  result = result.replaceAll("9", "៩");
  return result;
}

export function khmer_date(date: Date) {
  const str_date = dayjs(date).format("DD-MM-YYYY");
  const arr_date = str_date.split("-");
  const day = arr_date[0];
  const month = arr_date[1];
  const month_kh = khmer_month(month);
  const year = arr_date[2];

  return `${day}-${month_kh}-${year}`;
}

export function khmer_month(input: string) {
  const month = Number(input);
  let result = "";

  if (month === 1) result = "មករា";
  if (month === 2) result = "កុម្ភៈ";
  if (month === 3) result = "មិនា";
  if (month === 4) result = "មេសា";
  if (month === 5) result = "ឧសភា";
  if (month === 6) result = "មិថុនា";
  if (month === 7) result = "កក្កដា";
  if (month === 8) result = "សីហា";
  if (month === 9) result = "កញ្ញា";
  if (month === 10) result = "តុលា";
  if (month === 11) result = "វិច្ឆិកា";
  if (month === 12) result = "ធ្នូ";

  return result;
}

export function static_asset(fullpath: string) {
  return process.env.PUBLIC_URL + fullpath;
}
