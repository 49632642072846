import { Link } from "react-router-dom";
import { services } from "../../data";
import { Container, Banner } from "../../components";
import { static_asset } from "../../lib";

export default function Services() {
  return (
    <>
      <Banner imageUrl={static_asset("/assets/images/banner/services_banner.webp")} />
      <Container>
        <div className="py-3 md:py-6">
          <h1 className="font-bold text-xl md:text-3xl text-center uppercase py-3 md:py-6">Our Services</h1>
          <div className="block gap-4 md:grid grid-cols-2">
            {services.map((service, index) => {
              return (
                <Link
                  to={service.path}
                  key={index}
                  className="block m-3 md:m-0 border-2 rounded-md border-orange-700 gap-5 overflow-hidden "
                >
                  <div className="relative h-[300px] md:h-[400px] ">
                    <img className="w-[100%] h-[100%]" alt={service.title} src={service.imageUrl} />
                  </div>
                  <div className="text-center uppercase text-md md:text-lg font-bold py-1 md:py-2">{service.title}</div>
                </Link>
              );
            })}
          </div>
        </div>
      </Container>
    </>
  );
}
